import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            // users: {
            //     id: {
            //         $containsi: '',
            //     },
            // },
            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    description: {
                        $containsi: '',
                    },
                },
                {
                    users: {
                        id: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['users'],
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        allergies: [],
        query: initialQuery(),
        isDeleteAllergy: false,
    },
    getters: {
        getAllergyList(state) {
            return state.allergies;
        },
        getAllergyQuery(state) {
            return state.query;
        },
        getAllergy(state) {
            return state.allergy;
        },
        getIsDeleteAllergy(state) {
            return state.isDeleteAllergy;
        },
    },
    mutations: {
        setAllergyList(state, allergies) {
            state.allergies = allergies;
        },
        setAllergyQuery(state, query) {
            state.query = query;
        },
        resetAllergyQuery(state) {
            state.query = initialQuery();
        },
        setAllergy(state, allergyData) {
            state.allergyData = allergyData;
        },
        setAllergySearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].description.$containsi = search;
            state.query.filters.$or[2].users.id.$containsi = search;
        },
        setIsDeleteAllergy(state, bol) {
            state.isDeleteAllergy = bol;
        },
    },
    actions: {
        fetchAllergyList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/allergies?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchAllergy(ctx, id, query = {}) {
            query.populate = ['users'];

            return new Promise((resolve, reject) => {
                axios
                    .get(`/allergies/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateAllergy(ctx, data) {
            let query = qs.stringify({
                populate: ['users'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                axios
                    .put(`/allergies/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addAllergy(ctx, allergyData) {
            allergyData = _.assign({}, allergyData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/allergies', { data: allergyData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteAllergy(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/allergies/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyAllergies(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/allergies/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
